import IframeResizer from '@iframe-resizer/react';
import React, { useEffect, useState } from 'react';

export default function EventBooking({ fields }) {

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {

    const js = document.createElement("script");
    js.type = "text/javascript";
    js.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.14/iframeResizer.js";
    js.addEventListener('load', () => setLoaded(true))
    document.body.appendChild(js);

  }, []);

  useEffect(() => {
    if (!loaded) {
    }
    else {
      window.iFrameResize({ log: false, checkOrigin: false, enablePublicMethods: true }, '#bookingIframe');
    }
  }, [loaded]);

  if (fields.strBookingBugURL !== null) {
    return (
      <div className="" id="bookingIframeDiv" style={{ padding: '0.75rem' }}>
        <IframeResizer id="bookingIframe" src={fields.strBookingBugURL} className="col-12"  width="100%" title="bookingIframe" waitForLoad></IframeResizer>
      </div>
    )
  } else {
    return <></>;
  }
}
